#main-administracao{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 30px;

    a{
        width: 90%;
        margin: auto;

        .list-itens{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            border-radius: 15px;
            padding: 8% 3%;
            background-color: #fff;
            cursor: pointer;
            transition: 0.5s;

            &:hover{
                transform: scale(1.02);
                box-shadow: 0px 3px 6px rgb(0 0 0 / 32%);
            }
    
            svg{
                margin-bottom: 0.7em;
                font-size: 2em;
            }
    
            .content-item{
    
                h3{
                    margin-bottom: .7%;
                    font-weight: 400;
                }
    
                p{
                    color: rgb(0,0,0, .7);
                }
            }
        }
    }
}