#main-atalhos{
    align-content: flex-start;

    .content-atalhos{
        width: 100%;
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
        gap: 1em 2em;        

        li{
            background-color: #fff;
            width: 100%;
            height: 10em;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;
            position: relative;

            .icone-atalhos{
                background-color: #5183f2;
                width: 40%;
                aspect-ratio: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                color: white;
                font-size: 1.4em;
            }

            p{
                opacity: .8;
                text-align: center;
            }
        }
    }
}

#campos-novo-atalho{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;

    .campo-form{
        margin-bottom: 1.5%;
    }
}