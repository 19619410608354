.loading{
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    z-index: 99;
    background: #0000004d;
    display: flex;
    align-items: center;
    justify-content: center;

    .load-circle{
        width: 100px;
        height: 100px;
        background-color: transparent;
        border: 5px solid black;
        border-right: 5px solid transparent;
        border-radius: 50%;
        animation: rotate 2s linear infinite forwards;
    }
}