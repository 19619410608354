#main-outros{
    .conteudo-sinistros{
        width: 100%;
        height: 100%;
    }
}

.corpo-corretores{
    svg{
        &:hover{
            color: #c22333;
        }
    }
}

.tabela-users{
    width: 100%;

    .cabecalho-tabela{
        width: 100%;
        display: flex;
        background-color: #f3f4fb;
        font-weight: 700;

        div{
            padding: 1% 0.5%;
            font-size: 0.9em;
        }
    }

    .column-nome{
        width: 45%;
    }

    .column-email{
        width: 50%;
    }

    .column-add{
        width: 5%;
        padding: 0 !important;
        font-size: 1em !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .row-tabela{
        width: 100%;
        display: flex;

        &:hover{
            background-color: rgb(216, 216, 216);
        }

        div{
            padding: 1% 0.5%;
            font-size: 0.9em;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        svg{
            cursor: pointer;

            &:hover{
                color: #298129;
            }
        }
    }
}

@media print{
    @page {size: landscape}

    .aba-areas{
        display: none !important;
    }

    .buttons-conteudo{
        display: none !important;
    }

    .filtro-conteudo{
        display: none !important;
    }

    .lista-dados{
        border: 1px solid rgb(0 0 0 / .4);
    }

    .column-status{
        svg{
            display: none !important;
        }
    }

    .lista-dados{
        box-shadow: none !important;
    }
}