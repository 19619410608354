// Mixins

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

.cabecalho-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    // margin-bottom: 1.1em;
    height: 15%;

    button{
        border-radius: 4px;
        // padding: 1.2% 3%;
        height: 1.9em;
        width: 2.9em;
    }
}

#main-home{
    height: 100vh;
    align-content: space-around;

    .line-div-placeholder{
        height: 1em;
    }

    .square-div-placeholder{
        width: 40%;
        aspect-ratio: 1;
        border-radius: 10px;
    }

    .content-areas{
        height: 85%;
        overflow-y: auto;
        width: 100%;

        .column-zerado{
            height: 100%;
            width: 100%;
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .item-content-container{
            width: 98%;
            border-bottom: 1px solid rgb(0, 0, 0, .2);
            padding: 2% 1%;

            &:last-of-type{
                border: 0;
            }

            a{
                cursor: pointer;

                h3{
                    font-weight: 500;
                    font-size: 1em;
                    margin-bottom: 1%;
                }

                p{
                    font-size: 0.8em;
                    opacity: 0.7;
                    letter-spacing: 0.3px;
                }
            }
        }
    }

    .texto-principal-home{
        width: 40%;
        height: 40%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 5% 0 0;    
        padding: 1% 2%;

        h2{
            font-size: 2.5em;
            margin-bottom: 0.5em;
        }

        p{
            font-size: 1.2em;
            opacity: .7;
        }
    }

    .atalhos-home{
        width: 55%;
        height: 40%;
        padding: 1% 2%;
        transition: 0.8s;

        h3{
            font-size: 1.4em;
        }

        ul{
            height: 85%;
            display: grid; 
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            align-items: center;
            gap: 1em;

            li{
                background-color: #fff;
                width: 100%;
                height: 90%;
                // padding: 5% 0;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                cursor: pointer;
                position: relative;

                button{
                    position: absolute;
                    top: -5%;
                    right: -5%;
                    aspect-ratio: 1;
                    width: 20%;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 50;
                }

                .icone-atalhos{
                    background-color: #5183f2;
                    // padding: 5%;
                    width: 40%;
                    aspect-ratio: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    color: white;
                    font-size: 1.3em;
                }

                p{
                    opacity: .8;
                    text-align: center;
                }

                &.add-atalho{
                    border: 2px dashed #5183f2;
                }
            }
        }
    }

    .treinamentos-home{
        width: 60%;
        height: 52%;
        padding: 1% 2%;
        margin-right: 2%;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;

        h3{
            font-size: 1.4em;
        }

        ul{
            height: 85%;
            padding-right: 2%;
            overflow-y:overlay;
            width: 100%;

            .column-zerado{
                min-height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            li{
                background-color: #fff;
                margin-bottom: 2%;
                border-radius: 8px;
                padding: 1.5%;
                display: flex;
                align-items: center;

                &:last-of-type{
                    margin-bottom: 0;
                }

                .rounded-div-placeholder{
                    width: 5%;
                    border-radius: 50%;
                    aspect-ratio: 1;
                    margin-right: 2%;
                    display: flex;
                }

                a{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    width: 100%;

                    img{
                        width: 100%;
                        border-radius: 50%;
                        aspect-ratio: 1;
                        margin-right: 2%;
                    }

                    h4{
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 40%;
                        font-size: 1em;
                    }

                    p{
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 35%;
                        opacity: .7;
                    }

                    h5{
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 18%;
                        border-radius: 15px;
                        text-align: center;
                        color: #fff;
                        padding: .2% 0;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .anotacoes-home{
        width: 38%;
        height: 52%;
        padding: 1% 2%;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;

        ul{
            width: 100%;
            height: 85%;

            li{
                padding: 2%;
                background-color: #fff;
                display: flex;
                align-items: center;
                border-radius: 8px;
                margin-bottom: 2%;
                cursor: pointer;

                &.add-anotacao{
                    text-align: center;
                    border: 2px dashed #5183f2;

                    h4{
                        width: 100%;
                    }
                }

                svg{
                    width: 10%;

                    &.fa-minus{
                        color: #5183f2;
                    }

                    &.fa-circle{
                        color: #ffff00;
                    }

                    &.fa-check{
                        color: #217515;
                    }
                }

                h4{
                    width: 90%;
                }
            }
        }
    }

    // APAGAR
    .saudacoes-user{
        width: 40%;
        height: 40%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 5% 0 0;    
        padding: 1% 2%;
        border-radius: 15px;
        background-color: #217515;
        color: #fff;
        box-shadow: 0px 3px 6px #00000029;

        h2{
            margin-bottom: 1%;
        }

        img{
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    .area-atalhos{
        width: 55%;
        height: 40%;
        padding: 1% 2%;
        // margin-right: 5%;
        @include boxRounded(#fff);
        transition: 0.8s;

        &:hover{
            box-shadow: 0px 3px 6px rgb(0 0 0 / 50%);
        }

        .lista-atalhos{
            line-height: 1.8;
            max-height: 80%;
            overflow-y: auto;

            li{
                display: flex;
                align-items: center;
                cursor: pointer;

                svg{
                    margin-right: 2%;
                    color: rgb(255,193,7, 0.9);
                }
            }
        }
    }

    .area-treinamentos{
        width: 40%;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        height: 52%;
        padding: 1% 2%;
        @include boxRounded(#fff);
        transition: 0.8s;

        &:hover{
            box-shadow: 0px 3px 6px rgb(0 0 0 / 50%);
        }

        .compromissos-geral{
            overflow-y: auto;
            max-height: 80%;
            width: 100%;

            .compromisso-mes{
                margin: 2% 0;

                .mes-compromisso-agenda{
                    font-size: 1.1em;
                    color: rgb(0,0,0, 0.8);
                    margin-bottom: 2%;
                }

                .box-compromisso-agenda{
                    display: flex;
                    align-items: center;
                    margin: 0 0 1.5% 2%;
                    cursor: pointer;

                    .dia-compromisso{
                        font-size: 1em;
                        padding: 0.3% 2% 0.3% 0;
                        margin-right: 2%;
                        border-right: 3px solid black;
                        
                        &:last-of-type{
                            margin-bottom: 2%;
                        }
                    }

                    .content-compromisso{
                        line-height: 1.5;
                        font-size: 0.9em;
                    }
                }
            }
        }
    }

    .anotacoes-user{
        height: 52%;
        width: 55%;
        padding: 1% 2%;
        margin-left: 5%;
        @include boxRounded(#fff);
        transition: 0.8s;

        &:hover{
            box-shadow: 0px 3px 6px rgb(0 0 0 / 50%);
        }

        .lista-anotacoes{
            height: 80%;
            overflow-y: auto;
            padding-right: 2%;

            .column-zerado{
                height: 100%;
                width: 100%;
                padding: 0 !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            li{
                cursor: pointer;
                margin: 2% 0 3%;
                padding: 3%;
                display: flex;
                background: #f1f1f1;
                flex-wrap: wrap;
                align-items: center;
                border-radius: 5px;

                &:last-of-type{
                    margin-bottom: 0 !important;
                }

                h4{
                    width: 100%;
                }
            }
        }
    }

    // ----------------------------
}

#campos-nova-anotacao{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;

    .campo-form{
        margin-bottom: 1.5%;

        &:first-of-type{
            margin-bottom: 3%;
        }
    }
}

#campos-novo-atalho{
    .container-search{
        width: 100%;
        margin-bottom: 1.5em;
    }

    ul{
        height: 85%;
        width: 100%;
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        gap: 2em 1em;
        overflow: auto;
        max-height: 25em;
        padding-right: 2%;

        li{
            width: 100%;
            height: 100%;
            background-color: #efefef;
            padding: 1em 0;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;

            .icone-atalhos{
                background-color: #5183f2;
                // padding: 5%;
                width: 40%;
                aspect-ratio: 1;
                margin-bottom: 1em;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                color: white;
                font-size: 1.3em;
            }

            p{
                opacity: .8;
                text-align: center;
            }

            &.add-atalho{
                border: 2px dashed #5183f2;
            }
        }
    }
}

.paragrafo-modal{
    padding: 0 1.5%;
    margin-bottom: 3%;
}