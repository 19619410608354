.main{
    .conteudo-chamado{
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .informacoes-chamado{
            width: 100%;
            
            .post-chamado{
                width: 100%;
                padding: 2%;
                background-color: #fff;
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 1.5em;

                &:last-of-type{
                    margin-bottom: 0;
                }

                .informacoes-autor{
                    width: 15%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    img{
                        width: 50%;
                        aspect-ratio: 1;
                        object-fit: cover;
                        border-radius: 50%;
                        margin-bottom: 7%;
                    }

                    span{
                        font-size: .85em;
                        color: rgba(0, 0, 0, 0.7);
                    }
                }

                .descricao-post{
                    margin-left: 5%;
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    time{
                        color: rgba(0, 0, 0, 0.7);
                        font-size: 0.9em;
                        margin-bottom: 2%;
                        width: 100%;
                    }

                    p{
                        margin-bottom: 5%;
                        width: 100%;
                        font-size: .9em;
                    }

                    button{
                        width: 15%;
                        padding: .5%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        svg{
                            margin-right: 5%;
                        }
                    }
                }
            }
        }
    }
}