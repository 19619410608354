.modal-small{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 99;

    .fundo-modal{
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgb(0 0 0 / 40%);
    }

    .content-modal{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        width: 25%;
        z-index: 50;
        border-radius: 7px;
        box-shadow: 0px 0px 10px 1px #00000017;
        border: 1px solid rgb(0,0,0, 20%);
        padding: 1%;

        svg{
            font-size: 3em;
            color: #c22333;
            margin-bottom: 5%;
        }

        h5{
            font-weight: 400;
            font-size: .9em;
            text-align: center;

            span{
                font-weight: 500;
            }
        }
    }

    button{
        padding: 0.4em 1.5em;
        margin: 0 1%;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 4px;
    }

    .buttons-form{
        margin-top: 5%;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}