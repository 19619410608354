// Mixins

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

#campos-nova-seguradora{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;

    h4{    
        width: 100%;
        margin: 1% 0 2%;
        padding: 0 0 0 1.5%;
    }
}

#main-locais{
    .conteudo-locais{
        width: 100%;
        height: 100%;

        .filtro-conteudo{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 2% 1%;
            border-radius: 4px;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            background-color: #fff;


            #src-geral-sinistro{
                width: 77%;
                padding: .8% 1%;
            }

            .filtro-avancado{
                background-color: rgb(126, 126, 126);
                color: #fff;
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }

            .filtro-buscar{
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                border-radius: 4px;
                padding: 0 2%;
                cursor: pointer;
            }
        }

        .lista-dados{
            width: 100%;
            background-color: #fff;
            border-radius: 4px;
            margin: 2% 0 0%;
            padding: 2% 1%;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            display: flex;
            flex-direction: column;

            .column-razao-social{
                width: 45%;
            }

            .column-cnpj{
                width: 15%;
            }

            .column-telefone{
                width: 15%;
            }

            .column-email{
                width: 25%;
            }

            .cabecalho-dados{
                width: 100%;
                display: flex;
                background-color: #f3f4fb;
                font-weight: 700;

                div{
                    padding: 1% 0.5%;
                    font-size: 0.9em;
                }
            }

            .corpo-dados{
                width: 100%;
                display: flex;
                flex-direction: column;
                font-weight: 400;

                .row-empresas{
                    width: 100%;
                    display: flex;
                    cursor: pointer;

                    div{
                        padding: 1% 0.5%;
                        font-size: 0.8em;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;

                        span{
                            margin-left: 1%;
                            font-weight: 500;
                        }
                    }
                }

                h5{
                    margin: 1% 1% 0 0;
                    text-align: end;
                }
            }
        }
    }

    .row-dados{
        cursor: pointer;
    }
}

@media print{
    @page {size: landscape}

    .aba-areas{
        display: none !important;
    }

    .buttons-conteudo{
        display: none !important;
    }

    .filtro-conteudo{
        display: none !important;
    }

    .lista-dados{
        border: 1px solid rgb(0 0 0 / .4);
    }

    .column-status{
        svg{
            display: none !important;
        }
    }

    .lista-dados{
        box-shadow: none !important;
    }
}