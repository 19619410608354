// Mixins

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

main{
    .conteudo-chamados{
        width: 100%;
        height: 100%;
        
        .lista-chamados{
            display: flex;
            flex-direction: column;
            width: 100%;

            .item-chamados{
                width: 100%;
                background-color: #fff;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 1.5% 1%;
                margin-bottom: .5em;
                box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

                >svg{
                    width: 5%;
                    margin-right: 1%;
                    font-size: 1.5em;
                }

                .titulo-categoria{
                    display: flex;
                    flex-direction: column;
                    width: 94%;

                    h2{
                        font-size: 1.2em;
                        margin-bottom: .1em;
                    }

                    .descricao-categoria{
                        opacity: .8;
                        font-size: .95em;
                    }
                }
            }
        }
    }
}

.lista-historicos{
    border-radius: 4px;
    // padding: 2% 1%;
    background-color: #fff;
    padding: 2%;
    margin-top: 2%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

@media print{
    @page {size: landscape}

    .aba-areas{
        display: none !important;
    }

    .buttons-conteudo{
        display: none !important;
    }

    .filtro-conteudo{
        display: none !important;
    }

    .lista-dados{
        border: 1px solid rgb(0 0 0 / .4);
    }

    .column-status{
        svg{
            display: none !important;
        }
    }

    .lista-dados{
        box-shadow: none !important;
    }
}