.notification{
    width: 25vw;
    padding: 1em 2em;
    border-radius: 5px;
    margin-bottom: .8em;
    box-shadow: 0 4px 4px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.06);
    background-color: #fff;
    position: relative;

    &:last-of-type{
        margin: 0;
    }

    .header-notification{
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        h3{
            font-weight: 500;
            font-size: 1em;
            margin-left: .5em;
        }

        svg{
            font-size: 1.4em;

            &.fa-circle-xmark{
                color: red;
            }

            &.fa-circle-check{
                color: green;
            }

            &.fa-circle-exclamation{
                color: orange;
            }

            &.fa-circle-info{
                color: blue;
            }
        }

        .icon-close-notification{
            position: absolute;
            margin: .4em .5em;
            font-size: 1.1em;
            cursor: pointer;
            top: 0;
            right: 0;
        }
    }

    p{
        opacity: .7;
        font-size: .9em;
    }
}

.area-notification{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99;
    padding: 1.5em;
}