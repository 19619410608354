// Mixins

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

main{
    .conteudo-treinamentos{
        width: 100%;
        height: 100%;
        
        .lista-treinamentos{
            display: grid;
            grid-template-columns: calc(100% / 3 - 1.33em) calc(100% / 3 - 1.33em) calc(100% / 3 - 1.33em);
            gap: 1em 2em;

            .item-treinamento{
                width: 100%;
                border-radius: 5px;
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                background-color: #fff;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                padding: 1em;
                transition: 0.5s;

                &:hover{
                    box-shadow: 0px 3px 6px rgb(0 0 0 / 35%);
                }

                .informacoes-treinamento{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    
                    .titulo-treinamento{
                        margin-bottom: 7%;

                        h3{
                            font-size: 1em;
                            font-weight: 400;
                            margin-bottom: 1em;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;

                            svg{
                                margin-right: .5em;

                                &.fa-circle{
                                    color: #007bff;
                                }

                                &.fa-circle-xmark{
                                    color: #dc3545;
                                }

                                &.fa-circle-check{
                                    color: #2f912f;
                                }
                            }
                        }

                        >p{
                            font-size: .8em;
                            opacity: .7;
                            letter-spacing: 0.3px;
                        }
                    }

                    .infos-treinamento{
                        display: flex;

                        .item-infos-treinamento{
                            display: flex;
                            align-items: center;
                            font-size: .9em;
                            margin-right: 2.5em;

                            p{
                                margin-left: .5em;
                                opacity: .7;
                            }

                            span{
                                padding: 2% 25%;
                                font-size: .9em;
                                color: #fff;
                                border-radius: 15px;
                                background-color: #298129;
                            }
                        }
                    }
                }
            }
        }
    }
}

#campos-novo-treinamento{
    display: flex;
    flex-wrap: wrap;
    max-height: 70vh;
    overflow-y: auto;

    h5{
        button{
            padding: .5% 1%;
            aspect-ratio: 1;
        }
    }
}

// #main-historicos{
//     height: auto;

    .lista-historicos{
        border-radius: 4px;
        // padding: 2% 1%;
        background-color: #fff;
        padding: 2%;
        margin-top: 2%;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    }
// }

@media print{
    @page {size: landscape}

    .aba-areas{
        display: none !important;
    }

    .buttons-conteudo{
        display: none !important;
    }

    .filtro-conteudo{
        display: none !important;
    }

    .lista-dados{
        border: 1px solid rgb(0 0 0 / .4);
    }

    .column-status{
        svg{
            display: none !important;
        }
    }

    .lista-dados{
        box-shadow: none !important;
    }
}