#main-certificados{
    height: 100vh;
    width: 100%;
    margin: 0 !important;

    #certificado{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        // position: relative;
        width: 200mm;
        height: 140mm;
        // aspect-ratio: 1 / 0.7;
        // transform: scale(.5);

        button{
            padding: 1% 2%;
            position: absolute;
            border-radius: 15px;
            top: 10px;
            right: 10px;
        }
    
        .frente-certificado{
            background-color: #fff;
            padding: 0 4% 4% 4%;
            border: 3px solid green;
            width: 200mm;
            height: 140mm;
    
            .topo-frente-certificado{
                text-align: center;
                display: flex;
                align-items: center;
                padding-bottom: 2%;
                height: 25%;
        
                .campo-topo-left, .campo-topo-right{
                    width: 30%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    img{
                        height: 85%;
                    }
    
                    svg{
                        display: none;
                    }
                }
        
                h1{
                    width: 40%;
                    text-transform: uppercase;
                    margin-bottom: 2%;
                    font-size: 1.7em;
                }
            }
        
            h2{
                text-align: center;
                text-transform: uppercase;
                font-size: 1.2em;
                padding-bottom: 4%;
                font-weight: 600;
                height: 10%;
            }
        
            .descricao-certificado{
                text-align: justify;
                font-size: 0.88em;
                padding: 0 3%;
                height: 30%;
                line-height: 1.5;
            }
        
            .local-treinamento{
                text-align: center;
                font-size: 1em;
                font-weight: 500;
                height: 22%;
            }
        
            .assinaturas-certificado{
                display: flex;
                justify-content: space-between;
                height: 13%;
        
                div{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 35%;
                    border-top: 1px solid rgba(0, 0, 0, 0.7);
                    padding-top: 1%;
                    position: relative;
        
                    p{
                        font-size: 0.67em;
                    }

                    img{
                        width: 45%;
                        top: -80%;
                        position: absolute;
                    }
                }
            }
        }
        
        .verso-certificado{
            background-color: #fff;
            padding: 4%;
            border: 3px solid green;
            flex-direction: column;
            align-items: center;
            width: 200mm;
            height: 140mm;
        
            h2{
                text-transform: uppercase;
                margin-bottom: 5%;
                font-size: 1em;
            }
        
            ul{
                width: 80%;
                font-size: 0.8em;
                line-height: 1.2;
        
                li{
                    list-style: inside;
                    margin-bottom: 1.5%;
                }
            }
        }
    }
}

// @media screen and (max-width: 205mm) {
//     #certificado{
//         width: 50% !important;
//         aspect-ratio: 1 / 0.70;

//         .frente-certificado{
//             width: 100% !important;
//             aspect-ratio: 1 / 0.70;
//             margin: 0 !important;
//             border: 0 !important;
//             break-after: page !important;
            
//             .topo-frente-certificado{
//                 h1{
//                     font-size: 2.5em !important;
//                 }
    
//                 svg{
//                     display: block !important;
//                 }
//             }
    
//             h2{
//                 font-size: 1.8em !important;
//             }
    
//             .descricao-certificado{
//                 font-size: 1.3em !important;
//             }
    
//             .local-treinamento{
//                 font-size: 1.5em !important;
//             }
    
//             .assinaturas-certificado{
//                 p{
//                     font-size: 1em !important;
//                 }
//             }
//         }
    
//         // .verso-certificado{
//         //     display: flex !important;
//         //     width: 297mm !important;
//         //     height: 209mm !important;
//         //     margin: 0 !important;
//         //     border: 0 !important;
    
//         //     h2{
//         //         font-size: 1.5em !important;
//         //     }
    
//         //     ul{
//         //         font-size: 1.2em !important;
//         //     }
//         // }
//     }
// }

@media print{
    @page {
        width: 297mm !important;
        height: 210mm !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    body, #root, main{
        margin: 0 !important;
        padding: 0 !important;
        width: 297mm !important;
        height: 419mm !important;
    }

    #certificado{
        height: 419mm !important;
        width: 297mm !important;

        button{
            display: none;
        }
    }

    .frente-certificado{
        width: 297mm !important;
        height: 209mm !important;
        margin: 0 !important;
        border: 0 !important;
        break-after: page !important;
        
        .topo-frente-certificado{
            h1{
                font-size: 2.5em !important;
            }

            svg{
                display: none !important;
            }
        }

        h2{
            font-size: 1.8em !important;
        }

        .descricao-certificado{
            font-size: 1.3em !important;
        }

        .local-treinamento{
            font-size: 1.5em !important;
        }

        .assinaturas-certificado{
            p{
                font-size: 1em !important;
            }
        }
    }

    .verso-certificado{
        display: flex !important;
        width: 297mm !important;
        height: 209mm !important;
        margin: 0 !important;
        border: 0 !important;

        h2{
            font-size: 1.5em !important;
        }

        ul{
            font-size: 1.2em !important;
        }
    }
}