#main-usuarios{
    align-content: flex-start;

    .content-usuarios{
        width: 100%;
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr; 
        gap: 1em 2em; 
        // padding: 0 2%;
    
        .list-itens{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
            border-radius: 5px;
            padding: 1em 0 0;
            margin-bottom: 1%;
            background-color: #fff;
    
            svg{
                font-size: 1.2em;
            }
    
            .content-item{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .img-user{
                    width: 6em;
                    height: 6em;
                    border-radius: 50%;
                    margin-bottom: 1em;

                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .titulo-usuario{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    padding: 0 0 1em;

                    h3{
                        font-size: 1em;
                        margin-bottom: .1em;
                    }

                    span{
                        color: rgb(0 0 0 / 70%);
                        font-size: .8em;
                    }
                }

                .campo-info-user{
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:last-of-type{
                        margin-bottom: 1.5em;
                    }

                    h4{
                        font-size: .8em;
                        margin-right: 0.4em;
                    }

                    h5{
                        font-size: .8em;
                        font-weight: 400;
                        color: rgb(0 0 0 / 70%);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 200px;
                    }
                }
            }

            .button-desativado{
                cursor: not-allowed;
                color: #838383;
            }

            button{
                display: flex;
                font-size: 0.9em;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                width: 50%;
                padding: 2%;
                user-select: none;
                cursor: pointer;

                &:first-of-type{
                    border-radius: 0 0 0 5px;
                }

                &:last-of-type{
                    border-radius: 0 0 5px 0;
                }

                &:hover{
                    background-color: #ececec;
                }

                svg{
                    font-size: 1em;
                    margin-right: 5%;
                }
            }
        }
    }
}

#campos-novo-usuario{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;

    .campo-form{
        margin-bottom: 1.5%;
    }
}