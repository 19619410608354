// Mixins

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

main{
    .conteudo-cursos{
        width: 100%;
        height: 100%;
        
        .lista-cursos{
            display: grid;
            grid-template-columns: calc(91% / 4) calc(91% / 4) calc(91% / 4) calc(91% / 4);
            gap: 1em 3%;
            max-width: 100%;

            .item-curso{
                width: 100%;
                border-radius: 5px;
                box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
                background-color: #fff;
                padding: 5% 2%;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                transition: 0.5s;
                position: relative;

                &:hover{
                    box-shadow: 0px 3px 6px rgb(0 0 0 / 35%);
                }

                .dados-curso{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    .titulo-curso{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin-bottom: 2%;

                        h3{
                            max-width: 50%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        span{
                            max-width: 45%;
                            background-color: red;
                            padding: 0.8% 3%;
                            border-radius: 15px;
                            color: #fff;
                            margin-left: 5%;
                            font-size: 0.7em;
                            font-weight: 400;
                            letter-spacing: 0.5px;
                            opacity: 0.8;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }

                    .info-curso{
                        text-align: center;
                        font-size: 0.8em;
                        opacity: 0.8;
                    }
                }

                hr{
                    width: 90%;
                    margin: 7% 0;
                }

                .valores-curso{
                    width: 100%;
                    display: flex;
                    
                    .item-valor-curso{
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &:first-of-type{
                            border-right: 1px solid rgba(0, 0, 0, 0.4);
                        }

                        h4{
                            font-size: 0.8em;
                            margin-bottom: 2%;
                        }

                        p{
                            opacity: .8;
                        }
                    }
                }

                // .options-curso{
                //     width: 100%;
                //     height: 30px;
                //     background-color: #ff0000;
                //     position: absolute;
                //     bottom: -30px;
                // }
            }
        }
    }
}

.modal-curso{
    h5{
        ul{
            margin-top: 1%;
            max-height: 15em;
            overflow-y: auto;

            li{
                list-style: inside;
            }
        }
    }
}

#campos-novo-curso{
    display: flex;
    flex-wrap: wrap;
    max-height: 70vh;
    overflow-y: auto;
}

#campos-editar-curso{
    display: flex;
    flex-wrap: wrap;
    max-height: 70vh;
    overflow-y: auto;

    .container-inputs{
        &>div{
            flex-direction: row;

            button{
                background-color: #dc3545;
                color: #fff;
            }
        }
    }
}

.lista-historicos{
    border-radius: 4px;
    // padding: 2% 1%;
    background-color: #fff;
    padding: 2%;
    margin-top: 2%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}
// }

@media print{
    @page {size: landscape}

    .aba-areas{
        display: none !important;
    }

    .buttons-conteudo{
        display: none !important;
    }

    .filtro-conteudo{
        display: none !important;
    }

    .lista-dados{
        border: 1px solid rgb(0 0 0 / .4);
    }

    .column-status{
        svg{
            display: none !important;
        }
    }

    .lista-dados{
        box-shadow: none !important;
    }
}